<template>
  <base-section
    id="News"
    class="accent"
  >
    <div class="bg-pixel" />
    <base-section-heading
      class="primary--text"
      title="News"
    />
    <v-container class="pr-16 pl-16" />
    <div
      class="py-14"
    />
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="240"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/swissshow.jpg')"
              alt="DPC Swiss Show"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="ml-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              Stadt, Land, Talent
            </h3>
            <div
              class="py-2"
            />
            <v-divider
              horizontal
              width="470"
              class="divider"
            />
            <div
              class="py-4"
            />
            <p
              class="text-h6 mr-12 secondary--text text-none text-left"
            >
              Portrait, DPC Crew
            </p>
            <div
              class="py-1"
            />
            <div class="text-h6 mr-12 primary--text text-none text-left">
              <a href="https://www.youtube.com/watch?v=Ee5PaAIeSMc">Youtube</a>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
      <div
        class="py-14"
      />
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="240"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/BS_Shows_SwissShow.jpg')"
              alt="DPC Swiss Show"
            >
          </v-avatar>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <div class="pl-6 mr-8">
            <h3 class="text-uppercase text-h5 text-none primary--text">
              SWISS SHOW
            </h3>
            <div
              class="py-2"
            />
            <v-divider
              horizontal
              devider
              width="470"
              class="divider"
            />
            <div
              class="py-4"
            />
            <p
              class="text-h6 mr-10 text-none secondary--text text-left"
            >
              BBCS - BBoy Connection Switzerland
            </p>
            <div
              class="py-3"
            />
            <div class="text-h6 mr-12 primary--text text-none text-left">
              <a href="https://www.youtube.com/watch?v=9Xt555cyHBY">Youtube</a>
              <br>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
    </v-container>
    <div
      class="py-16"
    />
    <div class="bg-pixel-down" />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent!important;
}

.list-header{
  font-size: 1.3rem!important;
  font-weight: 700;
  letter-spacing: 0;
}

.list-content{
  font-size: 1rem!important;
  font-weight: 600;
  line-height: 1.5rem;
}

.divider{
  border-color: rgba(0, 0, 0, 0.59)!important;
  border-width: 2px 0 0 0;

}
.v-application .text-h5{
  font-weight: 900;
  font-size: 1.5rem;
}
.v-application .accent{
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.bg-pixel{
  background-image:url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  height: 80px;
  background-size:contain;
  width:100%;
  position:relative;
  z-index: 3;
}
.bg-pixel-down {
  background-image:url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  transform: scaleY(-1);
  height: 80px;
  background-size:contain;
  margin-top:-80px;
  width:100%;
  position:absolute;
  z-index: 3;
}

</style>
